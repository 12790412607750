import { type default as CurrencyType } from "@interfaces/Currency";
import { useConfigurationStore } from "@stores/configuration";
import axios from "@utilities/axios";
import { isDebugMode } from "@utilities/helper";

export const getCurrency = (value: string): CurrencyType | undefined => {
  const configurationState = useConfigurationStore.getState();

  const currency = configurationState.currencies.find(
    (item: CurrencyType) => item.currency === value,
  );
  return currency;
};

export const calculateCurrency = (
  currencyName: string,
  price: string | number,
): number => {
  const currency = getCurrency(currencyName);
  let conversionRate = 1;
  const value = typeof price === "string" ? parseFloat(price) : price;

  if (!!currency && !!currency.conversion_rate) {
    conversionRate = parseFloat(currency.conversion_rate);
  }

  return value !== 0 && conversionRate !== 0
    ? Math.round((value * 100) / conversionRate) / 100
    : 0;
};

export const loadCurrencies = async (
  language: string = "en",
): Promise<void> => {
  const configurationState = useConfigurationStore.getState();

  const url = "/country_rates_ext/";
  const formData = new FormData();
  formData.append("action", "currencies");
  formData.append("bundle_name", process.env.GATSBY_BUNDLE_NAME ?? "");
  formData.append("language", language);
  try {
    const response = await axios.post(url, formData);
    if (isDebugMode()) {
      console.log("JSON response:", response.data);
    }
    configurationState.setCurrencies(
      response.data?.popular_currencies?.concat(
        response.data?.other_currencies,
      ),
    );
  } catch (error: any) {
    if (error.response) {
      console.error(`Response status: ${error.response.status}`);
    } else {
      console.error(error.message);
    }
  }
};
