const enum CookieType {
  LANG = "lang",
  TOKEN = "lpbt",
  ACCOUNT_NUMBER = "lpbt_acc",
  HASHED_PASSWORD = "lpbt_hs",
  CURRENCY = "currency",
  AFFILIATE = "affiliate",
}

export const CookieOptions: Cookies.CookieAttributes = {
  domain: process.env.GATSBY_ROOT_DOMAIN,
  httpOnly: false,
  secure: true,
  sameSite: "strict",
};

export default CookieType;
