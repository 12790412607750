import CookieType, { CookieOptions } from "@constants/CookieType";
import SocialProvider from "@constants/SocialProvider";
import { useConfigurationStore } from "@stores/configuration";
import axios from "@utilities/axios";
import { encryptedStorage, hashKey } from "@utilities/encrypted-storage";
import { isDebugMode } from "@utilities/helper";
import Cookies from "js-cookie";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface AuthenticationState {
  token: string;
  account_number: string;
  hashed_password: string;
  register: (
    fullName: string,
    email: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
    alreadyHaveAccountCallback?: (socialAccounts: SocialProvider[]) => void,
  ) => Promise<void>;
  login: (
    phoneNumber: number | string,
    password: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  reloadLogin: (
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  loginWithLicenseToken: (
    licenseToken: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  getToken: () => string;
  setToken: (value: string) => void;
  isAuthenticated: () => boolean;
  clear: () => void;
}

export const useAuthenticationStore = create<AuthenticationState>()(
  persist(
    (set, get) => ({
      token: "",
      account_number: "",
      hashed_password: "",
      register: async (
        fullName: string,
        email: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
        alreadyHaveAccountCallback?: (socialAccounts: SocialProvider[]) => void,
      ) => {
        const voucherCode = (
          useConfigurationStore.getState().affiliate ?? "site"
        ).toUpperCase();

        const searchLicenseFormData = new FormData();
        searchLicenseFormData.append("action", "search-licenses");
        searchLicenseFormData.append("contact_email", email);
        const searchLicenseRequest = "/sim_card_activation/";
        axios
          .post(searchLicenseRequest, searchLicenseFormData)
          .then(response => {
            if (
              response.data.social_to_propose &&
              Object.values(SocialProvider).includes(
                response.data.social_to_propose as SocialProvider,
              )
            ) {
              if (alreadyHaveAccountCallback) {
                if (callback) {
                  callback();
                }
                alreadyHaveAccountCallback([response.data.social_to_propose]);
              }
            } else {
              activate();
            }
          })
          .catch(error => {
            if (errorCallback) {
              errorCallback(error);
            }
          })
          .finally(() => {});

        const activate = async (): Promise<void> => {
          let hm = "";
          let nn = "";
          try {
            if (typeof window !== "undefined" && window.generateHmacAndNonce) {
              const result = await window.generateHmacAndNonce();
              hm = result.hmacToken;
              nn = result.nonce;
            }
          } catch (error) {
            console.error("Error generating HMAC and Nonce:", error);
          }
          const activateFormData = new FormData();
          activateFormData.append("is_app_registration", "1");
          activateFormData.append("from", "esim");
          activateFormData.append("fullname", fullName);
          activateFormData.append("contact_email", email);
          activateFormData.append("voucher-code", voucherCode);
          activateFormData.append("h", hm);
          activateFormData.append("n", nn);

          const activateRequest = "/sim_card_activation/";

          axios
            .post(activateRequest, activateFormData)
            .then(response => {
              const transactionId = response.data.message;
              if (!transactionId) {
                if (errorCallback) {
                  errorCallback({
                    message: "",
                  });
                }
                if (callback) {
                  callback();
                }
                return;
              }
              const formData = new FormData();
              formData.append("is_app_registration", "1");
              formData.append("voucher-code", voucherCode);
              formData.append("transaction-id", response.data.message);
              formData.append("contact_email", email);
              const checkRequest = "/sim_card_activation_status/";
              startCheckingAccountInterval(checkRequest, formData);
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback(error);
              }
            })
            .finally(() => {});
        };

        let interval: NodeJS.Timeout;
        let isRegisterSuccessful = false;
        const startCheckingAccountInterval = (
          request: string,
          formData: FormData,
        ): void => {
          interval = setInterval(() => {
            checkActivationStatus(request, formData);
          }, 5000);
          setTimeout(() => {
            if (!isRegisterSuccessful) {
              if (isDebugMode()) {
                console.log("signing up timeout");
              }
              clearInterval(interval);
              if (errorCallback) {
                errorCallback({
                  message: "",
                });
              }
              if (callback) {
                callback();
              }
            }
          }, 30000);
        };

        const checkActivationStatus = (
          request: string,
          formData: FormData,
        ): void => {
          axios
            .post(request, formData)
            .then(response => {
              if (isDebugMode()) {
                console.log("Request success : ", response.data);

                console.log("Request success result: " + response.data.message);
              }

              if (response.data.message === "2") {
                clearInterval(interval);
                isRegisterSuccessful = true;

                const licenseToken =
                  response.data.username + ":" + response.data.hashed_password;

                get().loginWithLicenseToken(
                  licenseToken,
                  () => {
                    if (callback) {
                      callback();
                    }
                  },
                  response => {
                    if (successCallback) {
                      successCallback(response);
                    }
                  },
                  error => {
                    if (errorCallback) {
                      errorCallback(error);
                    }
                  },
                );
              }
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback(error);
              }
            });
        };
      },
      login: async (
        phoneNumber: number | string,
        password: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        let isCallbackAlreadyCalled = false;
        let isErrorCallbackAlreadyCalled = false;
        const formData = new FormData();
        formData.append("format", "json");
        formData.append("cloud_username", phoneNumber.toString());
        formData.append("cloud_password", password);
        const autoProvisionRequest = "/autoprovision/";

        axios
          .post(autoProvisionRequest, formData)
          .then(response => {
            if (isDebugMode()) {
              console.log("JSON response:", response.data);
            }
            const licenseToken =
              response.data.cloud_username +
              ":" +
              response.data.hashed_password;
            get().loginWithLicenseToken(
              licenseToken,
              () => {
                if (callback && !isCallbackAlreadyCalled) {
                  callback();
                  isCallbackAlreadyCalled = true;
                }
              },
              response => {
                if (successCallback) {
                  successCallback(response);
                }
              },
              error => {
                if (errorCallback && !isErrorCallbackAlreadyCalled) {
                  errorCallback(error);
                  isErrorCallbackAlreadyCalled = true;
                }
              },
            );
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
            if (errorCallback && !isErrorCallbackAlreadyCalled) {
              errorCallback(error);
              isErrorCallbackAlreadyCalled = true;
            }
          })
          .finally(() => {
            if (callback && !isCallbackAlreadyCalled) {
              callback();
              isCallbackAlreadyCalled = true;
            }
          });
      },
      reloadLogin: async (
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        const accountNumberCookie = Cookies.get(CookieType.ACCOUNT_NUMBER);
        const accountNumberStored = get().account_number;
        const hashedPasswordCookie = Cookies.get(CookieType.HASHED_PASSWORD);
        const hashedPasswordStored = get().hashed_password;

        let account_number = "";
        let hashed_password = "";
        if (
          accountNumberCookie &&
          accountNumberCookie === accountNumberStored
        ) {
          account_number = accountNumberCookie;
        }
        if (
          hashedPasswordCookie &&
          hashedPasswordCookie === hashedPasswordStored
        ) {
          hashed_password = hashedPasswordCookie;
        }

        if (!account_number || !hashed_password) {
          if (errorCallback) {
            errorCallback(null);
            return;
          }
        }
        const licenseToken = account_number + ":" + hashed_password;
        get().loginWithLicenseToken(
          licenseToken,
          callback,
          successCallback,
          errorCallback,
        );
      },
      loginWithLicenseToken: async (
        licenseToken: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        const loginFormData = new FormData();
        const loginRequest = "/login/";
        if (isDebugMode()) {
          console.log("loginRequest:", loginRequest);
        }
        axios
          .post(loginRequest, loginFormData, {
            headers: {
              Authorization: `Bearer ${licenseToken}`,
            },
          })
          .then(response => {
            const loginCredentials = licenseToken.split(":");
            const accountNumber = loginCredentials[0];
            const hashedPassword = loginCredentials[1];
            set({
              token: response.data.token,
              account_number: accountNumber,
              hashed_password: hashedPassword,
            });
            Cookies.set(CookieType.TOKEN, response.data.token, CookieOptions);
            Cookies.set(
              CookieType.ACCOUNT_NUMBER,
              accountNumber,
              CookieOptions,
            );
            Cookies.set(
              CookieType.HASHED_PASSWORD,
              hashedPassword,
              CookieOptions,
            );
            if (successCallback) {
              successCallback(response);
            }
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
            if (errorCallback) {
              errorCallback(error);
            }
          })
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      },
      getToken: () => {
        return get().token;
      },
      setToken: (value: string) => {
        set({
          token: value,
        });
      },
      isAuthenticated: () => {
        return !!get().token;
      },
      clear: () => {
        set({
          token: "",
          account_number: "",
          hashed_password: "",
        });
      },
    }),
    {
      name: hashKey("auth-storage"),
      skipHydration: true,
      storage: createJSONStorage(() => encryptedStorage),
    },
  ),
);

export const useAuthenticationState = (): AuthenticationState => {
  return useAuthenticationStore(state => {
    return state;
  });
};
