import React, { type FC, type ReactElement } from "react";
import { toast } from "react-toastify";
import { useI18next } from "gatsby-plugin-react-i18next";

import AlertModal from "@components/alert_modal";
import { clearUserData, defaultRipple } from "@utilities/helper";

const LogoutModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}> = ({ isModalOpen, setIsModalOpen }): ReactElement => {
  const { t, navigate } = useI18next();

  const submitLogout = (): void => {
    clearUserData();

    setIsModalOpen(false);
    toast.success(t("logout_success"), {
      autoClose: 5000,
    });
    navigate("/login");
  };

  return (
    <AlertModal
      type="warning"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div className="flex flex-col gap-4">
        <span className="w-full text-center">
          {t("you_want_to_log_out_dialog")}
        </span>
        <div className="flex flex-row gap-4">
          <button
            ref={defaultRipple}
            className="custom-btn-primary w-full"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            {t("cancel_camule_case")}
          </button>
          <button
            ref={defaultRipple}
            className="custom-btn-secondary w-full"
            onClick={submitLogout}
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </AlertModal>
  );
};

export default LogoutModal;
