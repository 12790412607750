import moment from "moment";
import { create } from "zustand";

export interface PaymentState {
  paymentIntentId: string;
  clientSecret: string;
  productId: number | string;
  total: number | string;
  currency: string;
  appliedCouponCode: string;

  isSmartTopUpChecked: boolean;
  scheduleDate: string;

  isCheckout: boolean;
  isOrdered: boolean;

  isAutoCheckout: boolean;

  setPaymentIntentId: (value: string) => void;
  setClientSecret: (value: string) => void;
  setProductId: (value: number | string) => void;
  setTotal: (value: number | string) => void;
  setCurrency: (value: string) => void;
  setAppliedCouponCode: (value: string) => void;
  setIsSmartTopUpChecked: (value: boolean) => void;
  setScheduleDate: (value: string) => void;
  setIsCheckout: (value: boolean) => void;
  setIsOrdered: (value: boolean) => void;
  setIsAutoCheckout: (value: boolean) => void;
  clear: () => void;
}

export const usePaymentStore = create<PaymentState>(set => ({
  paymentIntentId: "",
  clientSecret: "",
  productId: "",
  total: 0,
  currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
  appliedCouponCode: "",
  isSmartTopUpChecked: false,
  scheduleDate: moment().format("YYYY-MM-DD"),
  isCheckout: false,
  isOrdered: false,
  isAutoCheckout: false,
  setPaymentIntentId: (value: string) => {
    set({
      paymentIntentId: value,
    });
  },
  setClientSecret: (value: string) => {
    set({
      clientSecret: value,
    });
  },
  setProductId: (value: number | string) => {
    set({
      productId: value,
    });
  },
  setTotal: (value: number | string) => {
    set({
      total: value,
    });
  },
  setCurrency: (value: string) => {
    set({
      currency: value,
    });
  },
  setAppliedCouponCode: (value: string) => {
    set({
      appliedCouponCode: value,
    });
  },
  setIsSmartTopUpChecked: (value: boolean) => {
    set({
      isSmartTopUpChecked: value,
    });
  },
  setScheduleDate: (value: string) => {
    set({
      scheduleDate: value,
    });
  },
  setIsCheckout: (value: boolean) => {
    set({
      isCheckout: value,
    });
  },
  setIsOrdered: (value: boolean) => {
    set({
      isOrdered: value,
    });
  },
  setIsAutoCheckout: (value: boolean) => {
    set({
      isAutoCheckout: value,
    });
  },
  clear: () => {
    set({
      paymentIntentId: "",
      clientSecret: "",
      productId: "",
      total: 0,
      currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
      appliedCouponCode: "",
      isSmartTopUpChecked: false,
      scheduleDate: moment().format("YYYY-MM-DD"),
      isCheckout: false,
      isOrdered: false,
      isAutoCheckout: false,
    });
  },
}));

export const usePaymentState = (): PaymentState => {
  return usePaymentStore(state => {
    return state;
  });
};
