import React, { type FC, useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { LiaCheckCircle } from "react-icons/lia";
import { useI18next } from "gatsby-plugin-react-i18next";

import Modal from "@components/modal";
import CookieType, { CookieOptions } from "@constants/CookieType";
import Languages from "@data/languages.json";
import type Language from "@interfaces/Language";
import { useAuthenticationState } from "@stores/authentication";
import axios from "@utilities/axios";
import { loadCurrencies } from "@utilities/currencies";
import { cn, primaryRipple } from "@utilities/helper";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from "js-cookie";

const LangModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}> = ({ isModalOpen, setIsModalOpen }) => {
  const { t, changeLanguage, language } = useI18next();
  const authenticationState = useAuthenticationState();

  const [startLoadingLang, setStartLoadingLang] = useState<string>("");
  const updateLang = async (lang: string): Promise<void> => {
    setStartLoadingLang(lang);
    Cookies.set(CookieType.LANG, lang, CookieOptions);
    setIsModalOpen(false);
    loadCurrencies(lang);
    if (authenticationState.isAuthenticated()) {
      const formData = new FormData();
      formData.append("action", "update-language");
      formData.append("language", lang);
      const changeLanguageUrl = "/my_account_details/";
      await axios.post(changeLanguageUrl, formData);
    }
    await changeLanguage(lang);
  };
  useEffect(() => {
    setStartLoadingLang("");
  }, [isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isCloseButtonExist={true}
      bgColor={"#f3f3f3"}
      title={t("language") ?? ""}
      isContentScrollable={true}
    >
      <div className="flex flex-col gap-3">
        {Languages.map((lang: Language, i: number) => {
          return (
            <button
              ref={primaryRipple}
              key={lang.name}
              onClick={() => {
                updateLang(lang.id);
              }}
              className={cn(
                "space-between focus:custom-primary-focus-ring group flex w-full items-center rounded-3xl !border-2 border-solid border-primary-200 p-3 shadow-lg shadow-gray-300 transition duration-300",
                {
                  "bg-primary": lang.id === language,
                  "bg-white hover:opacity-80": lang.id !== language,
                },
              )}
            >
              <span
                className={cn(
                  "block w-full no-underline transition duration-300",
                  {
                    "text-white": lang.id === language,
                    "text-black group-hover:text-primary": lang.id !== language,
                  },
                )}
              >
                {lang.name}
              </span>
              <AnimatePresence>
                <>
                  {!(
                    startLoadingLang !== "" && lang.id === startLoadingLang
                  ) && (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <LiaCheckCircle className="size-5 text-white" />
                    </motion.span>
                  )}
                  {startLoadingLang !== "" && lang.id === startLoadingLang && (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <CgSpinner className="size-5 animate-spin text-primary" />
                    </motion.span>
                  )}
                </>
              </AnimatePresence>
            </button>
          );
        })}
      </div>
    </Modal>
  );
};

export default LangModal;
